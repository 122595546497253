import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import { Card } from 'react-bootstrap'
import Layout from '../components/layout'
import Seo from '../components/seo'

export const Projects = () => (
  <Layout className="project__h1">
    <Seo title="Projects" />
    <Card style={{ width: '18rem', margin: '2rem' }}>
      <Card.Body>
        <Card.Text>
          <a href="https://www.capitolstonks.com">
            <StaticImage
              src="../images/capstonks.png"
              quality={100}
              formats={['auto', 'webp', 'avif']}
              alt="capitolstonks"
              width={300}
              style={{ marginBottom: '1.45rem' }}
            />
          </a>
        </Card.Text>
      </Card.Body>
    </Card>
  </Layout>
)

export default Projects
